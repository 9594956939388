import { Avatar, Skeleton } from 'antd';
import { presignGetImage } from 'api/file.api';
import { Color, randomAvatarColor } from 'hooks/getRandomAvatarColor';
import { useEffect, useState } from 'react';

interface IRenderPhoto {
  employeeId: string;
  size: 'sm' | 'original' | 'lg' | 'md';
  name: string;
  lastName: string;
}

const RenderPhotos = ({ employeeId, size, name, lastName }: IRenderPhoto) => {
  const avatarSize = {
    sm: 40,
    md: 100,
    lg: 170,
    original: 170,
  };
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');
  const [imageLoadError, setImageLoadError] = useState(false);
  const [styleColor, setStyleColor] = useState<Color>();

  useEffect(() => {
    if (!styleColor) {
      setStyleColor(randomAvatarColor());
    }
  }, [styleColor]);

  const handleOnError = () => {
    setImageLoadError(true);
    return true;
  };
  const getPhoto = async () => {
    await presignGetImage({
      fileName: employeeId,
      folder: `profile_picture/${size}`,
    }).then((response) => {
      setUrl(response.data.url);
      setLoading(false);
    });
  };
  
  useEffect(() => {
    getPhoto();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[employeeId]);

  return (
    <>
      {!loading ? (
        url && !imageLoadError ? (
          <Avatar
            size={avatarSize[size]}
            src={url}
            onError={() => {
              return handleOnError();
            }}
          />
        ) : (
          <Avatar size={avatarSize[size]} style={styleColor}>
            {name.charAt(0)}
            {lastName.charAt(0)}
          </Avatar>
        )
      ) : (
        <Skeleton.Avatar
          active={loading}
          size={avatarSize[size]}
          shape={'circle'}
        />
      )}
    </>
  );
};

export default RenderPhotos;
