import { Table } from 'antd';
import { HolidaysListColumns } from './HolidaysListColumns';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { doDeleteHoliday, doEditHoliday } from 'store/slices/holidaySlice';
import { Holiday } from 'interfaces/holidays.interfaces';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { FormDrawer } from 'components/common/Drawer/FormDrawer';
import { holidayFormItems } from 'constants/forms/holidays';
import { SliceStatus } from 'constants/enums/slices';
import { Dayjs } from 'dayjs';
import openNotification from 'hooks/notificationHook';
import { TableProps } from 'antd/lib/table';

const tableProps: TableProps<Holiday> = {
  bordered: true,
  tableLayout: 'fixed',
  size: 'small',
  pagination: { position: ['bottomCenter'], pageSize: 50 },
  className: 'table-ps',
};

export const HolidaysList = ({
  setCurrentYear,
}: {
  setCurrentYear: () => void;
}) => {
  const [form] = useForm();
  const { holidaysList } = useAppSelector((state) => state.holiday);
  const dispatch = useAppDispatch();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const { holidayStatus } = useAppSelector((state) => state.holiday);
  const [holidayToEditId, setHolidayToEditId] = useState<string>();

  const onDeleteHoliday = (id: string) => {
    dispatch(doDeleteHoliday(id)).then((result) => {
      if (/rejected/.test(result.type)) {
        openNotification({
          type: 'error',
          message: 'Error',
          description:
            'No fue posible eliminar el día festivo, por favor inténtalo de nuevo más tarde.',
        });
      } else {
        setCurrentYear();
        openNotification({
          type: 'success',
          message: '¡Éxito!',
          description: 'Se ha eliminado el día festivo exitosamente.',
        });
      }
    });
  };

  const onEditHoliday = (values: Holiday) => {
    if (holidayToEditId) {
      const date = values.date as Dayjs;
      values.date = date.endOf('day').toISOString();
      values._id = holidayToEditId;
      dispatch(doEditHoliday(values)).then((result) => {
        if (/rejected/.test(result.type)) {
          openNotification({
            type: 'error',
            message: 'Error',
            description: 'Ocurrió un error al editar el día festivo.',
          });
        } else {
          setCurrentYear();
          openNotification({
            type: 'success',
            message: '¡Éxito!',
            description: 'Se ha editado el día festivo con éxito.',
          });
          form.resetFields();
          setOpenEditDrawer(false);
        }
      });
    }
  };

  return (
    <div>
      <Table
        {...tableProps}
        data-cy="holidays-list-table"
        columns={HolidaysListColumns({
          onDelete: (id) => onDeleteHoliday(id),
          onOpenEditDrawer: (value) => setOpenEditDrawer(value),
          setItemToEditId: (id) => setHolidayToEditId(id),
          form,
        })}
        dataSource={holidaysList}
        rowKey="_id"
      />
      <FormDrawer<Holiday>
        open={openEditDrawer}
        title="Editar día festivo"
        width={500}
        confirmText="Editar"
        cancelText="Cancelar"
        formMode="edit"
        formItems={holidayFormItems}
        loading={holidayStatus === SliceStatus.Updating}
        onConfirm={(values) => onEditHoliday(values)}
        onOpen={() => setOpenEditDrawer(true)}
        onClose={() => {
          setOpenEditDrawer(false);
        }}
        form={form}
        dataCy="edit-holiday"
      />
    </div>
  );
};
